@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tilt+Warp&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Tahoma, Verdana, sans-serif;
  height: 100vh;
  transition: background-color 0.5s ease, color 0.5s ease;
}

.adminItemTableHeader {
  border-style: solid;
  border-width: 2px;
}

.adminItemTableRow {
  border-style: solid;
  border-width: 0px 2px 2px 2px;
}

.mainMenuButton {
  font-family: "Tilt Warp", sans-serif;
  font-weight: 700;
}

.copyright {
  font-size: 0.75rem;
}

/* Define styles for light mode */
[data-bs-theme="light"] body {
  background-color: #ffffff;
  color: #000000;
}

/* Define styles for dark mode */
[data-bs-theme="dark"] body {
  background-color: #333333;
  color: #ffffff;
}

x a {
  text-decoration: none;
  color: #000;
}

.bordered {
  border: solid;
  padding: 1rem;
}

.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.header {
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 60px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: #828282;
}

.descriptionRow {
  text-align: left;
}

.descriptionTitle {
  font-size: medium;
  font-weight: 700;
}

.descriptionContent {
  font-size: medium
}

.createdByContent,
.timesSpunContent {
  font-size: smaller;
}

.form,
.content {
  width: 100%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.btn {
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}


.btn-reverse {
  background: #fff;
  color: #000;
}

.btn-block {
  width: 100%;
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(0.98);
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

table {
  width: 90%;
  height: 90%;
  margin: 0 auto;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.inline-input {
  display: flex;
  align-items: center;
  /* Vertically center the label and input */
  margin-bottom: 10px;
  /* Adjust the spacing between each label-input pair */
}

.inline-input label {
  flex: 1;
  /* Allow the label to grow and take available space */
  margin-right: 10px;
  /* Adjust the spacing between label and input */
}

.inline-input input[type="range"] {
  flex: 2;
  /* Allow the input to grow and take available space */
  /* Add any additional styles as needed */
}

.warning {
  color: red;
  font-weight: bold
}

#useGameChangerFont {
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
}

.red-slider::-webkit-slider-thumb {
  background-color: red;
}

.red-slider::-moz-range-thumb {
  background-color: red;
}

.red-slider::-ms-thumb {
  background-color: red;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .form {
    width: 90%;
  }

  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}